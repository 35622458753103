jQuery(document).ready(function () {

    let recreationalTennis    = jQuery('#field_1_3 .ginput_container_select')
    let wheelchairSelect      = jQuery('#field_1_6 .ginput_container_select')
    let wheelchairDescription = jQuery('#field_1_6 .gfield_description').text()

    if (window.matchMedia("(max-width: 768px)").matches) {

        jQuery('#field_1_6 .gfield_description').text('')

        recreationalTennis.appendTo('#field_1_3 .gfield_label')
        wheelchairSelect.appendTo('#field_1_6 .gfield_label')
        jQuery('#field_1_6 .gfield_label').append( wheelchairDescription )

    }

})
//
// jQuery(window).on('resize', function () {
//
//     let recreationalTennis    = jQuery('#field_1_3 .ginput_container_select')
//     let wheelchairSelect      = jQuery('#field_1_6 .ginput_container_select')
//     let lastFieldBackup       = jQuery('#field_1_6').clone(true, true)
//     let wheelchairDescription = jQuery('#field_1_6 .gfield_description').text()
//                                 jQuery('#field_1_6 .gfield_description').text('')
//
//     if (window.matchMedia("(max-width: 768px)").matches) {
//
//         recreationalTennis.appendTo('#field_1_3 .gfield_label')
//         wheelchairSelect.appendTo('#field_1_6 .gfield_label')
//         jQuery('#field_1_6 .gfield_label').append( wheelchairDescription )
//
//     } else {
//
//         recreationalTennis.appendTo('#field_1_3')
//         wheelchairSelect.appendTo('#field_1_6')
//         jQuery('#field_1_6').replaceWith( lastFieldBackup )
//
//     }
//
// })

import 'infinite-scroll/dist/infinite-scroll.pkgd'

let loadMore = jQuery('.load-more')

if (loadMore.length > 0) {

    let iSc = jQuery('.repeater__holder').infiniteScroll({
        path: '.load-more__button',
        checkLastPage: true,
        append: '.repeater__holder .repeater',
        loadOnScroll: false,
        scrollThreshold: false,
        button: '.load-more',
        status: '.page-load-status',
    })

    // iSc.on('append.infiniteScroll', (response, path, items) => {
    //     // jQuery('.repeater').find('img').each((index, img) => {
    //     //     img.outerHTML = img.outerHTML
    //     // })
    // })

}

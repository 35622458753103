ga4code = window.ga4_analytics_id;

window.dataLayer = window.dataLayer || [];
function at_gtag(){dataLayer.push(arguments);}
at_gtag('js', new Date());
at_gtag('config', ga4code);

function sendImpression(brand, adlabel){
    if(brand != undefined){
        __gaTracker('send', {
            hitType: 'event',
            eventCategory: "impression",
            eventAction: brand,
            eventLabel: adlabel,
            transport: 'beacon'
        });
    }
}

function sendClick(adbrand, adlocation, adlink) {
  // console.log(adbrand. adlocation, adlink);
  if(adbrand != undefined && adlocation !="" && adlink != "#"){
    __gaTracker('send', {
      hitType: 'event',
      eventCategory: "click",
      eventAction: adbrand,
      eventLabel: adlocation,
      transport: 'beacon'
    });
  }
}
function sendImpression_GA4 (adbrand, adlang, adcampaign, adspotlabel, adpageslug) {
    at_gtag('event', 'rain_ad_impression', {
        'ad_brand': adbrand,
        'ad_lang': adlang,
        'ad_campaign': adcampaign,
        'ad_location': adspotlabel,
        'ad_page_slug': adpageslug
    });
}
function sendClick_GA4(o) {
    adbrand = jQuery(o).data('brand');
    if (adbrand != undefined && jQuery(o).attr('href') != '#') {
        adlang = jQuery(o).data('lang');
        adcampaign = jQuery(o).data('campaign');
        adspotlabel = jQuery(o).data('adspotlabel');
        adpageslug = jQuery(o).data('page_slug');
        at_gtag('event', 'rain_ad_click', {
            'ad_brand': adbrand,
            'ad_lang': adlang,
            'ad_campaign': adcampaign,
            'ad_location': adspotlabel,
            'ad_page_slug': adpageslug
        });
    }
}

jQuery('body').on('click', '.ad-click-ga', function (e) {
    // e.preventDefault()
    adbrand = jQuery(this).data('brand');
    adlocation = jQuery(this).data('label');
    adlink = jQuery(this).attr('href');
    sendClick(adbrand,adlocation,adlink);
    sendClick_GA4(this);
});

if (typeof __gaTracker === "undefined") {
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','//www.google-analytics.com/analytics.js','__gaTracker');
__gaTracker('create', window.analyticsID);
__gaTracker('send','pageview');
}

///////////////////

var all_ad_spots = jQuery('.ad-insert').map( function(){
    return jQuery(this).attr('id')
}).get()

let ad_spots = ''

if( all_ad_spots.length > 0 ){
    ad_spots = all_ad_spots.filter( function(elem, index, self) {
        return index == self.indexOf(elem)
    })
}

if ( ad_spots.length > 0 ) {
    loadAds(ad_spots)
    //console.log(ad_spots.length)
}

let page_slug = ''

if( jQuery('meta[property="og:url"]').attr('content') )
    page_slug = jQuery('meta[property="og:url"]').attr('content').replace( window.rest_api, '' ).replace(/\//g, '' )

function loadAds(ads){
    jQuery.ajax({
        method: 'GET',
        url: window.adserver + '/' + window.site + '-' + window.lang + '/',
        data: {v: '2', ads: ads.join(',') },
        dataType: 'json',
        cache: true
    })
    .done(function (obj) {

        var ad_spot, ad_html, adlabel, link, brand, campaign, img, html, ad_width, ad_height, ad_type, ad_size, noMobileAd;

        var adsToShow = {};
        var adsArray = []

        for (var ad in obj.ads){
            if( !adsToShow[obj.ads[ad].ad_slug] )
                adsToShow[obj.ads[ad].ad_slug] = []

            adsToShow[obj.ads[ad].ad_slug].push( obj.ads[ad] )
        }

        for( var ad in adsToShow ){
            if( adsToShow[ad].length > 1 ){
                let intervalInt = getRandomInt( 0, adsToShow[ad].length - 1 )

                let intervalAd = adsToShow[ad][intervalInt]

                adsToShow[ad] = []
                adsToShow[ad].push( intervalAd )
            }

            adsArray.push( adsToShow[ad][0] )
        }

        obj.ads = adsArray

        for (var ad in obj.ads) {

            link          = obj.ads[ad].ad_href;
            brand         = obj.ads[ad].ad_brand;
            campaign      = obj.ads[ad].ad_campaign;
            ad_width      = obj.ads[ad].ad_img_width;
            ad_height     = obj.ads[ad].ad_img_height;
            ad_type       = obj.ads[ad].ad_type;
            ad_size       = obj.ads[ad].ad_size;
            ad_spot       = obj.ads[ad].ad_slug;
            ad_spot_label = ad_spot;

            /*
                Page: lang | brand | campaign | page_[location eg. top] | [page slug]
                Post: lang | brand | campaign | post_[location eg. top, card, logo] | [page slug]
                All others: lang | brand | campaign | ad_spot | [page slug]
            */
            if ( ad_spot_label.startsWith('spost2_') === true) {
                /*spost2_sobeys_scores_top*/
                var adloc = '';
                if ( ad_spot_label.endsWith('_card') === true) {
                    adloc = 'card';
                } else if ( ad_spot_label.endsWith('_logo') === true) {
                    adloc = 'logo';
                } else {
                    adloc = 'top';
                }
                var sadtype = jQuery( '.' + ad_spot_label ).data('sadtype');
                if (sadtype == 'post') {
                    ad_spot_label = 'sponsored_post_' + adloc;
                } else {
                    ad_spot_label = 'sponsored_page_' + adloc;
                }
            }

            if (page_slug == '') {
                page_slug = 'home';
            }

            adlabel = window.lang + " | " + brand + " | " + campaign + " | " + ad_spot_label + " | " + page_slug;


            rest_of_data_html = ' data-lang="' + window.lang + '" data-adspotlabel="' + ad_spot_label + '" data-page_slug="' + page_slug + '"' ;

            if( ad_type === "html" ){

                html = decodeHtml(obj.ads[ad].ad_html);
                if (link == '') {
                    ad_html = '<div class="ad-click-ga" data-category="' + window.lang + '" data-label="' + adlabel + '" data-brand="' + brand +'" data-campaign="' + campaign +'" href="' + link + '" target="_blank" ' + rest_of_data_html + ' >' + html + '</div>';
                } else {
                ad_html = '<a class="ad-click-ga" data-category="' + window.lang + '" data-label="' + adlabel + '" data-brand="' + brand +'" data-campaign="' + campaign +'" href="' + link + '" target="_blank" ' + rest_of_data_html + ' >' + html + '</a>';
                }
                sendImpression(brand, adlabel);
                sendImpression_GA4 (brand, window.lang, campaign, ad_spot_label, page_slug);

            } else if ( ad_type === 'standard' ){

                img = obj.ads[ad].ad_img;

                var cssClass = 'ad-click-ga standard-mirror'

                if( ad_spot == 'news_listing' )
                    cssClass += ' rogers-leaderboard'

                if( obj.ads[ad].ad_img_mobile != '' )
                    cssClass += ' has-mobile-ad'

                if( obj.ads[ad].ad_img_mobile == '' && window.matchMedia("(max-width: 768px)").matches )
                    noMobileAd = true

                ad_html = '<a class="' + cssClass + '" data-category="' + window.lang + '" data-label="' + adlabel + '" data-brand="' + brand +'" data-campaign="' + campaign +'" href="' + link + '" target="_blank" ' + rest_of_data_html + ' >'
                    ad_html += '<img class="desktop" src="' + img + '" width="'+ ad_width +'" height="'+ ad_height +'" alt="' + obj.ads[ad].ad_img_alt + '">'

                    if( obj.ads[ad].ad_img_mobile != '' )
                        ad_html += '<img class="mobile" src="' + obj.ads[ad].ad_img_mobile + '" alt="' + obj.ads[ad].ad_img_mobile_alt + '">'
                ad_html += '</a>';

                sendImpression(brand, adlabel);
                sendImpression_GA4 (brand, window.lang, campaign, ad_spot_label, page_slug);

            } else {

                let ad_logo, ad_image, ad_description, ad_cta, ad_overlay_color, ad_alignment, ad_class = '';

                ad_logo          = obj.ads[ad].ad_img_overlay;
                ad_image         = obj.ads[ad].ad_img;
                ad_description   = obj.ads[ad].ad_description;
                ad_cta           = obj.ads[ad].ad_cta;
                ad_overlay_color = obj.ads[ad].ad_overlay_colour;
                ad_alignment     = obj.ads[ad].ad_alignment;

                if( ad_size == 'top' || ad_size == 'takeovertop' || ad_size == 'takeoverbottom' || ad_size == 'listing' ){

                    if( ad_type == 'cta' )
                        ad_class = 'rogers-leaderboard__cta'
                    else if( ad_type == 'aligned' )
                        ad_class = 'rogers-leaderboard__aligned--' + ad_alignment
                    else if( ad_type == 'logo' )
                        ad_class = 'rogers-leaderboard-logo'

                    if( ad_size == 'takeovertop' || ad_size == 'takeoverbottom' )
                        ad_class += ' ' + ad_size

                    ad_html = '<div class="rogers-leaderboard ' + ad_class + '">';

                            ad_html += '<a class="rogers-leaderboard__figure ad-click-ga" href="' + link + '" data-category="' + window.lang + '" data-label="' + adlabel + '" data-brand="' + brand +'" data-campaign="' + campaign +'" target="_blank" ' + rest_of_data_html + ' >'
                                ad_html += '<img src="' + ad_image + '" alt="' + obj.ads[ad].ad_img_alt + '">'
                            ad_html += '</a>'

                            ad_html += '<div class="rogers-leaderboard__content">'

                                if( ad_size == 'takeovertop' || ad_size == 'takeoverbottom' )
                                    ad_html += '<div class="rogers-leaderboard__takeoverwrapper">'

                                    if( ad_type == 'logo' ) {

                                        ad_html += '<div class="rogers-leaderboard__logo">'
                                            ad_html += '<img src="' + ad_logo + '">'
                                        ad_html += '</div>'

                                    } else if( ad_type == 'cta' ) {

                                        ad_html += '<div class="rogers-leaderboard__logo">'
                                            ad_html += '<img src="' + ad_logo + '">'
                                        ad_html += '</div>'

                                        ad_html += '<div class="rogers-leaderboard__wrap">'
                                            ad_html += '<div class="rogers-leaderboard__description">' + ad_description + '</div>'
                                            ad_html += '<a class="rogers-leaderboard__action ad-click-ga" href="' + link + '" data-category="' + window.lang + '" data-label="' + adlabel + '" data-brand="' + brand +'" data-campaign="' + campaign +'" target="_blank" ' + rest_of_data_html + ' >' + ad_cta + '</a>'
                                        ad_html += '</div>'

                                    } else if( ad_type == 'aligned' ) {

                                        ad_html += '<div class="rogers-leaderboard__wrap">'
                                            ad_html += '<div class="rogers-leaderboard__logo">'
                                                ad_html += '<img src="' + ad_logo + '">'
                                            ad_html += '</div>'

                                            ad_html += '<div class="rogers-leaderboard__description">' + ad_description + '</div>'
                                        ad_html += '</div>'

                                    }

                                if( ad_size == 'takeovertop' || ad_size == 'takeoverbottom' )
                                    ad_html += '</div>'

                            ad_html += '</div>'

                            ad_html += '<div class="rogers-leaderboard__decoration"></div>';

                            if( obj.ads[ad].ad_show_gradient == true ){

                                if( ad_overlay_color.length > 0 ){

                                    if( ad_type == 'aligned' ){

                                        if( ad_alignment == 'left' )
                                            ad_html += '<div class="rogers-leaderboard__overlay" style="background: linear-gradient(90deg, '+ad_overlay_color+' 0%, '+ad_overlay_color+' 30%, '+hexToRgb(ad_overlay_color)+' 100%)"></div>'
                                        else
                                            ad_html += '<div class="rogers-leaderboard__overlay" style="background: linear-gradient(90deg, '+hexToRgb(ad_overlay_color)+' 0%, '+ad_overlay_color+' 30%, '+ad_overlay_color+' 100%)"></div>'

                                    }
                                    else
                                        ad_html += '<div class="rogers-leaderboard__overlay" style="background-color:' + ad_overlay_color + '"></div>';

                                } else
                                    ad_html += '<div class="rogers-leaderboard__overlay"></div>'

                            }

                            if( ad_type == 'aligned' )
                                ad_html += '<div class="rogers-leaderboard__aligned-bg"></div>'

                    ad_html += '</div>';

                } else if ( ad_size == 'card' ){

                    if( ad_type == 'cta' )
                        ad_class = 'rogers-square__cta'
                    else if( ad_type == 'aligned' )
                        ad_class = 'rogers-square__bottom'

                    ad_html = '<div class="rogers-square ' + ad_class + '">';

                        ad_html += '<a class="rogers-square__figure ad-click-ga" href="' + link + '" data-category="' + window.lang + '" data-label="' + adlabel + '" data-brand="' + brand +'" data-campaign="' + campaign +'" target="_blank" ' + rest_of_data_html + ' >'
                            ad_html += '<img src="' + ad_image + '" alt="' + obj.ads[ad].ad_img_alt + '">'
                        ad_html += '</a>'

                        ad_html += '<div class="rogers-square__content">'

                            ad_html += '<div class="rogers-square__logo">'
                                ad_html += '<img src="' + ad_logo + '">'
                            ad_html += '</div>'

                            if( ad_type == 'cta' || ad_type == 'aligned' )
                                ad_html += '<div class="rogers-square__description">' + ad_description + '</div>'

                            if( ad_type == 'cta' )
                                ad_html += '<a class="rogers-square__action ad-click-ga" href="' + link + '" data-category="' + window.lang + '" data-label="' + adlabel + '" data-brand="' + brand +'" data-campaign="' + campaign +'" target="_blank" ' + rest_of_data_html + ' >' + ad_cta + '</a>'

                        ad_html += '</div>';

                        ad_html += '<div class="rogers-square__decoration"></div>';

                        if( obj.ads[ad].ad_show_gradient == true ) {
                            if( ad_overlay_color.length > 0 )
                                ad_html += '<div class="rogers-square__overlay" style="background-color:' + ad_overlay_color + '"></div>';
                            else
                                ad_html += '<div class="rogers-square__overlay"></div>';
                        }

                    ad_html += '</div>';

                } else if ( ad_size == 'logo' ){
                    img = obj.ads[ad].ad_img;

                    ad_html = '<a class="' + cssClass + '" data-category="' + window.lang + '" data-label="' + adlabel + '" data-brand="' + brand +'" data-campaign="' + campaign +'" href="' + link + '" target="_blank" ' + rest_of_data_html + ' ><img src="' + img + '" width="'+ ad_width +'" height="'+ ad_height +'"></a>';

                    sendImpression(brand, adlabel);
                    sendImpression_GA4 (brand, window.lang, campaign, ad_spot_label, page_slug);
                }


                sendImpression(brand, adlabel);
                sendImpression_GA4 (brand, window.lang, campaign, ad_spot_label, page_slug);

            }

            if ( ad_spot ) {

                //if( noMobileAd != true ){

                    jQuery( '.' + ad_spot ).html( ad_html ).removeClass( 'hide_ad' ).addClass( 'show_ad' )

                    // if( !jQuery('body').hasClass('has-cover-image') && jQuery( '.cover-ad' ).length > 0 ){
                    //     //console.log( 'dddd' )
                    //     jQuery('.intro').addClass( 'has-cookie' )
                    // }

                    if( jQuery('.' + ad_spot ).parent().hasClass( 'block__holder' ) )
                        jQuery('.' + ad_spot ).parent().addClass( 'ads' )

                    if( jQuery('.' + ad_spot ).hasClass( 'rogers-mirror--single' ) ){
                        jQuery('.news__top__holder').addClass( 'has-cookie' )
                        jQuery('.' + ad_spot ).addClass( 'rogers-mirror--single-post' )
                    }

                    if( jQuery('.' + ad_spot ).hasClass( 'news_listing' ) ){
                        //jQuery('.news-archive__menu__holder').addClass( 'ads' )
                        jQuery('.' + ad_spot ).css( { 'display' : 'flex' } )
                    }
                //}

            }
        }


    })
    .fail(function (jqXHR, textStatus) {
        console.log('Request failed: ' + textStatus);
    });
}

function decodeHtml(html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

jQuery(document).ready( function (){
    setTimeout( function(){
        if( !jQuery('.ad-insert').is( ':visible' ) ){
            jQuery('.news-archive__menu__holder').removeClass( 'ads' )
            jQuery('.intro').removeClass( 'ads' )
            jQuery('.news__top__holder').removeClass( 'has-cookie' )
        }
    }, 25 );
})

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? "rgba("+parseInt(result[1], 16)+", "+parseInt(result[2], 16)+", "+parseInt(result[3], 16)+", 0)" : null
  // return result ? {
  //   r: parseInt(result[1], 16),
  //   g: parseInt(result[2], 16),
  //   b: parseInt(result[3], 16)
  // } : null;
}

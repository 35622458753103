import 'select2/dist/js/select2.full.js'
import 'select2/dist/js/i18n/fr.js'


jQuery('.pfts select').select2({
    width: '100%'
}).on('change', function () {
    window.location = jQuery(this).val()
})

jQuery('.nav-dropdown select').select2({
    width: '100%',
}).show().on('change', function () {
    window.location = jQuery(this).val()
})

jQuery('.tournament-switcher').select2({
    width: '100%',
}).show().on('change', function () {
    window.location = jQuery(this).val()
})

jQuery('.gform_wrapper select').select2({
    width: '100%',
}).on( 'select2:open', function(e){

    if( jQuery(this).is('#input_1_3, #input_1_4, #input_1_5, #input_1_6, #input_11_3, #input_11_4, #input_11_5, #input_11_6') )
        jQuery('.select2-container--open').addClass('width-correction')

})

jQuery(document).on('gform_post_render', function(event, form_id, current_page){

    jQuery('.gform_wrapper select').select2({
        width: '100%',
    }).on( 'select2:open', function(e){

        if( jQuery(this).is('#input_1_3, #input_1_4, #input_1_5, #input_1_6, #input_11_3, #input_11_4, #input_11_5, #input_11_6') )
            jQuery('.select2-container--open').addClass('width-correction')

    })

});

let playerSearchConfig = {
    dropdownParent : jQuery('.player-profile__title-wrap')
}

if( window.lang && window.lang == 'fr' )
    playerSearchConfig.language = 'fr'

jQuery('.player-profile__search').select2({
    placeholder : 'Select',
    dropdownParent : jQuery('.player-profile__title-wrap'),
    searchInputPlaceholder : 'RECHERCHER'
}).on('change', function () {
    console.log( jQuery(this).val() )
    window.location = jQuery(this).val()
}).on('select2:opening', function() {

    setTimeout(function(){
        if( window.lang && window.lang == 'fr' )
            jQuery( '.select2-search__field' ).attr( 'placeholder', 'Recherche' )
    }, 1)

})

const partnersSlider = jQuery('.logo-bar'),
    partnersSlides = jQuery('.logo-bar .partner-logo')

if (partnersSlider.length > 0 && partnersSlides.length > 1) {

    partnersSlider.flickity({
        cellSelector : '.partner-logo',
        wrapAround   : true,
        cellAlign    : 'left',
        groupCells   : true,
        autoPlay     : true,
        draggable    : false,
        arrowShape: {
          x0: 20,
          x1: 50, y1: 35,
          x2: 60, y2: 30,
          x3: 35
        }
    })

}

let header = jQuery('.header')

jQuery('.body__before').on('click', function(e) {

    jQuery('.header .hamburger').trigger('click')

})

jQuery(document).on('keydown', function(event) {
    if (event.key == "Escape") {

        if( jQuery('body').hasClass('is-drawer') ){

            if (jQuery('.hamburger-toggle .hamburger').hasClass('is-active'))
                jQuery('.drawer').attr('tabindex', '-1')
            else
                jQuery('.drawer').attr('tabindex', '1').focus()

            jQuery('body').toggleClass('is-drawer')
            jQuery('.hamburger').toggleClass('is-active')
        }
    }
});


jQuery('.hamburger').on('click', function (e) {

    e.preventDefault()

    if (jQuery(this).hasClass('is-active')) {

        jQuery('.drawer').attr('tabindex', '-1')
        
    } else {
        jQuery('.drawer').attr('tabindex', '1').focus()

        setTimeout(
            function(){ jQuery('.drawer__search input').focus()
        }, 100)
    }

    jQuery('body').toggleClass('is-drawer')
    jQuery('.hamburger').toggleClass('is-active')

})

jQuery('.drawer__nav .toggle').on('click', function (e) {
    e.preventDefault()

    let thisEl = jQuery(this),
        parentEl = thisEl.parent()

    if (parentEl.hasClass('open')) {
        parentEl.toggleClass('open').next().slideToggle()
    } else {
        parentEl.toggleClass('open', false).next().slideToggle()
        parentEl.addClass( 'open' )
    }
})

var lastScrollTop = 0

jQuery(window).on('scroll', function () {
    let scrollTop = jQuery(window).scrollTop()

    if ( scrollTop > 40 ) {
        jQuery('.first-nav__holder').css( { 'visibility' : 'hidden', 'height' : '0' } )
        jQuery('.header').addClass( 'header--white' )
        jQuery('.header .tournament-switcher__holder').addClass( 'tournament-switcher--white' )
    } else {
        jQuery('.first-nav__holder').css( { 'visibility' : 'visible', 'height' : '80px' } )
        jQuery('.header').removeClass( 'header--white' )
        jQuery('.header .tournament-switcher__holder').removeClass( 'tournament-switcher--white' )
    }

    if( lastScrollTop > scrollTop ) {
        jQuery('.first-nav__holder').css( { 'visibility' : 'visible', 'height' : '80px' } )
        jQuery('.first-nav__holder').addClass( 'first-nav__holder--white' )
    }

    if( scrollTop > lastScrollTop || scrollTop <= 40 )
        jQuery('.first-nav__holder').removeClass( 'first-nav__holder--white' )

    lastScrollTop = scrollTop
})

jQuery(document).ready(function () {
    let scrollTop = jQuery(window).scrollTop()

    if ( scrollTop > 40 ) {
        jQuery('.first-nav__holder').css( { 'visibility' : 'hidden', 'height' : '0' } )
        jQuery('.header').addClass( 'header--white' )
    } else {
        jQuery('.first-nav__holder').css( { 'visibility' : 'visible', 'height' : '80px' } )
        jQuery('.header').removeClass( 'header--white' )
    }

    if( lastScrollTop > scrollTop ) {
        jQuery('.first-nav__holder').css( { 'visibility' : 'visible', 'height' : '80px' } )
        jQuery('.first-nav__holder').addClass( 'first-nav__holder--white' )
    }

    if( scrollTop > lastScrollTop || scrollTop <= 40 )
        jQuery('.first-nav__holder').removeClass( 'first-nav__holder--white' )

    lastScrollTop = scrollTop
})

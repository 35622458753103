import 'babel-polyfill'
import objectFitImages from 'object-fit-images'
import '@fancyapps/fancybox'
import 'reframe.js/dist/jquery.reframe.min'
import 'jquery-match-height/jquery.matchHeight'
import 'what-input'


import './ad-insert'
import './front-page'
import './content'
import './selects'
import './header'
import './load-more'
import './news'
import './footer'
import './quiz'
import './popup'


objectFitImages()

jQuery.fancybox.defaults.animationEffect = 'fade'
jQuery.fancybox.defaults.gash = false
jQuery.fancybox.defaults.buttons = [
    'close'
]
jQuery.fancybox.defaults.clickContent = false

jQuery( '[data-fancybox="popup"]' ).fancybox({
    arrows : false,
});

jQuery('.content iframe:not(.content .no-resize iframe)').reframe()

jQuery(document).ready(function () {
    // jQuery('.tickets-group__main').matchHeight( { byRow: true } )

    jQuery( '.content a:has(img)' ).css( { border: 'none' } )

    jQuery( '.tennis-101__item p' ).matchHeight( { byRow: true } )

    jQuery( '.player-profile__toggle' ).on( 'click', function() {
        if( jQuery(this).hasClass( 'active' ) ) {
            jQuery(this).removeClass( 'active' )
            jQuery( '.player-profile__search').select2('close')
        } else {
            jQuery(this).addClass( 'active' )
            jQuery( '.player-profile__search').select2('open')
        }
    })

    jQuery('.player-profile__search').on('select2:open', function(e){
        jQuery('.select2-search__field').attr('placeholder', 'Search');
    })

    jQuery( '.intro__toggle' ).on( 'click', function() {
        jQuery('.intro__nav').toggleClass( 'active' )
        jQuery(this).toggleClass( 'active' )
    })


    //Highlight News page when we are on a category
    if( jQuery( 'body' ).hasClass( 'category' ) ){
        jQuery( '.header__main-nav li:last-child' ).addClass( 'current-menu-item current_page_item current_page_parent' )
    }
})

function replaceUrlParam( url, paramName, paramValue ) {
    if (paramValue == null) {
        paramValue = '';
    }
    var pattern = new RegExp('\\b('+paramName+'=).*?(&|#|$)');
    if (url.search(pattern)>=0) {
        return url.replace(pattern,'$1' + paramValue + '$2');
    }
    url = url.replace(/[?#]$/,'');
    return url + (url.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue;
}

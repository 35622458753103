//I GUESS THIS SHOULD BE IN THE PLUGIN - @TODO
let accordion = jQuery('.mortar-accordion__title a')

accordion.on('click', function (e) {

    e.preventDefault()

    let thisEl = jQuery(this),
        thisParent = thisEl.parent()

    thisParent.toggleClass('open')

})

jQuery('.mortar-tabs__list li:first-child a').addClass('selected')

jQuery('.mortar-tabs__list a').on('click', function(e) {

    e.preventDefault()

    let parentLi = jQuery(this).parent()
    let parentLiIndex = parentLi.index()
    let wrapper = jQuery(this).parents('.mortar-tabs')

    wrapper.find('.mortar-tabs__list a').removeClass('selected')
    jQuery(this).addClass('selected')

    wrapper.find('.mortar-tabs__content').hide().eq(parentLiIndex).show()

})

const tvscheduleSlider = jQuery('.tv-schedule__carousel'),
    tvscheduleSlides = jQuery('.tv-schedule__carousel .tv-schedule__column')

if (tvscheduleSlider.length > 0 && tvscheduleSlides.length > 1) {

    tvscheduleSlider.flickity({
        cellSelector : '.tv-schedule__column',
        wrapAround   : false,
        cellAlign    : 'left',
        groupCells   : true,
        autoPlay     : false,
        draggable    : true,
        contain      : true,
        arrowShape   : {
          x0: 20,
          x1: 50, y1: 35,
          x2: 60, y2: 30,
          x3: 35
        }
    })

}

let newsSidebar = jQuery('.news__sidebar')

jQuery(window).on('resize', function () {

    if (window.matchMedia("(min-width: 1024px)").matches) {
        newsSidebar.appendTo('.news__wrapper')
    } else {
        newsSidebar.prependTo('.news__aside')
    }

})

jQuery(document).ready(function () {

    if (window.matchMedia("(min-width: 1024px)").matches) {
        newsSidebar.appendTo('.news__wrapper')
    } else {
        newsSidebar.prependTo('.news__aside')
    }

})

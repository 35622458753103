import Cookie from 'js-cookie'

if ( window.rogers_popup_enabled == 1 ) {

    let visits = Cookie.get( 'rogers_popup' )
   

    if ( typeof visits == 'undefined' )
        visits = { counter : 0, visited_urls : [] }

    let current_url = window.location.href

    var isAO = (val) => val instanceof Array || val instanceof Object ? true : false

    if ( !isAO( visits ) )
        visits = JSON.parse( visits )

    if ( visits.counter != 0 ) {

        if ( !visits.visited_urls.includes( current_url ) ) {
            visits.counter++
            visits.visited_urls.push( current_url )
        }
    } else {
        visits.counter = 1
        visits.visited_urls.push( current_url )
    }

    visits = JSON.stringify(visits);
    Cookie.set( 'rogers_popup', visits )

    let dismissed = Cookie.get( 'rogers_popup_dismissed' )

    setTimeout( function() {
        visits = JSON.parse( visits )
        if ( dismissed != 'yes' && visits.counter >= window.rogers_popup_pages_count )

            if( window.matchMedia("(min-width: 768px)").matches )
                jQuery( '.subscribe-popup, .subscribe-popup__overlay').fadeIn( 600 )
            else {
                if( jQuery('.zEWidget-launcher--active').length > 0 )
                    jQuery( '.subscribe-popup__trigger').css( 'bottom', jQuery('.zEWidget-launcher--active').height() + 18 )

                jQuery( '.subscribe-popup__trigger').fadeIn( 300 )
            }

    }, 50 * 100 )

    jQuery( document ).on( 'click', '.subscribe-popup__close', function(e){
        jQuery( '.subscribe-popup, .subscribe-popup__overlay' ).fadeOut()

        Cookie.set( 'rogers_popup_dismissed', 'yes' )
    })

    jQuery( document ).on( 'click', '.subscribe-popup__trigger__close', function(e){
        jQuery( '.subscribe-popup__trigger' ).fadeOut()

        Cookie.set( 'rogers_popup_dismissed', 'yes' )
    })

    jQuery( document ).on( 'click', '.subscribe-popup__trigger', function(e){
        jQuery( '.subscribe-popup__trigger' ).fadeOut()
        jQuery( '.subscribe-popup, .subscribe-popup__overlay').fadeIn( 600 )
    })

}
